<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" id="card_section">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="me-2 d-inline-block"><i class="bi bi-bank display-6 text-primary"></i></div>
              <div class="mb-2 mb-lg-0 d-inline-block">
                  <h4 class="fw-medium font-size-20 text-dark mb-0"> {{ title }}</h4>
                  <small class="d-block font-size-13 text-muted fw-normal">Bank Account Mangement</small>
              </div>
              <div class="ms-auto" v-if="create_modify_permission">
                <button class="btn btn-outline-info fw-medium me-2" @click="create(currentAccount)">
                  <i class="uil uil-plus font-size-16 me-1"></i> Add Bank Account
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col col-md-4 col-lg-4 mb-lg-3 mb-2">
                <div class="d-flex mb-0">
                <div class="app-search2 position-relative col">
                  <input
                    type="text"
                    placeholder="Bank Account No., Account Name, Bank Name"
                    class="form-control mb-0"
                    v-model.trim="search_value"
                    autocomplete="no"
                    @keydown.enter="handlePageChange(1)"
                    @search="handlePageChange(1)"
                  />
                  <span class="uil-search text-primary"></span>
                </div>
                <button
                  class="btn btn-light rounded ms-1"
                  type="button"
                  :disabled="disabled"
                  @click="search_value='', handlePageChange(1)"
                >
                  <span>Clear</span>
                </button>
              </div>
              </div>
              <div class="col-12 col-md-4 col-lg-4 mb-3">
                <div class="input-group" v-if="create_modify_permission" >
                  <span class="input-group-text text-primary fw-medium"><i class="bi bi-filter me-1"></i> Sort By</span>
                  <select class="form-select pe-5" v-model="sortByData" @change="handlePageChange(1);" :disabled="disabled">
                    <option value="">Default</option>
                    <option value="today_in_volume_asc">Today In Volume (Asc)</option>
                    <option value="today_in_volume_desc">Today In Volume (Desc)</option>
                    <option value="today_out_volume_asc">Today Out Volume (Asc)</option>
                    <option value="today_out_volume_desc">Today Out Volume (Desc)</option>
                    <option value="total_in_amount_asc">Total Lifetime Volume (Asc)</option>
                    <option value="total_in_amount_desc">Total Lifetime Volume (Desc)</option>
                    <option value="total_out_amount_asc">Total Payout/Settlement (Asc)</option>
                    <option value="total_out_amount_desc">Total Payout/Settlement (Desc)</option>
                    <option value="current_bank_balance_asc">Bank Account Balance (Asc)</option>
                    <option value="current_bank_balance_desc">Bank Account Balance (Desc)</option>
                    <option value="status_asc">Status(Asc)</option>
                    <option value="status_desc">Status (Desc)</option>
                  </select>
                </div>
              </div>
              <div class="col-auto ms-md-auto mb-3">
                <router-link :to="{name:'upi accounts'}" class="btn btn-info fw-medium">
                View UPI Accounts <i class="bi bi-arrow-right-circle ms-1"></i> 
                </router-link>
              </div>
              <div class="col-12">
                <div class="card border p-3 font-size-13 total-count-data">
                  <div class="row mb-3 border-bottom pb-3">
                    <div class="col-12 col-md-6 col-lg">
                      Today In Transaction
                      <h4 class="fw-medium mb-2 mb-md-0">
                        {{ summary_data.today_in_transaction }}
                      </h4>
                    </div>
                    <div class="col-12 col-md-6 col-lg">
                      Today In Volume
                      <h4 class="fw-medium mb-2 mb-md-0">
                        <span v-if="summary_data.today_in_volume"> {{ convertCurrencyFormat(summary_data.today_in_volume,true)}}</span>
                        <span v-else>{{ summary_data.today_in_volume }}</span>
                      </h4>
                    </div>
                    <div class="col-12 col-md-6 col-lg">
                      Total In Volume (Lifetime)
                      <h4 class="fw-medium mb-2 mb-md-0">
                        <span v-if="summary_data.total_in_volume"> {{ convertCurrencyFormat(summary_data.total_in_volume,true)}}</span>
                        <span v-else>{{ summary_data.total_in_volume }}</span>
                      </h4>
                    </div>
                    <div class="col-12 col-md-6 col-lg">
                      Total All Bank Acc. Balance
                      <h4 class="fw-medium mb-0 mb-md-0 text-orange">
                        
                        <span v-if="summary_data.total_all_bank_account_balance>0"> {{ convertCurrencyFormat(summary_data.total_all_bank_account_balance,true)}}</span>
                        <span v-else>{{ summary_data.total_all_bank_account_balance }}</span>
                      </h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6 col-lg">
                      Today Out Transaction
                      <h4 class="fw-medium mb-2 mb-md-0 text-danger">
                        {{ summary_data.today_out_transaction }}
                      </h4>
                    </div>
                    <div class="col-12 col-md-6 col-lg">
                      Today Out Volume
                      <h4 class="fw-medium mb-2 mb-md-0 text-danger">
                        <span v-if="summary_data.today_out_volume"> {{ convertCurrencyFormat(summary_data.today_out_volume,true)}}</span>
                        <span v-else>{{ summary_data.today_out_volume }}</span>
                      </h4>
                    </div>
                    <div class="col-12 col-md-6 col-lg">
                      Total Out Volume (Lifetime)
                      <h4 class="fw-medium mb-2 mb-md-0 text-danger">
                        <span v-if="summary_data.total_out_volume"> {{ convertCurrencyFormat(summary_data.total_out_volume,true)}}</span>
                        <span v-else>{{ summary_data.total_out_volume }}</span>
                      </h4>
                    </div>
                    <div class="col-12 col-md-6 col-lg">
                      Total Active Bank Acc.
                      <h4 class="fw-medium mb-2 mb-md-0 text-success">{{ summary_data.total_active_bank_account }}</h4>
                    </div>
                  </div>
                </div>
                <div class="table-responsive text-nowrap font-size-14 position-relative d-none">
                
                  <table class="table mt-0 mb-0 align-middle min-width-760 font-size-12" :class="{'table-hover':returnData.length>0 && !loading}">
                    <thead class="text-uppercase">
                      <tr>
                        <th>ID</th>
                        <th>Bank Account Name<br>Bank Account No.</th>         
                        <th>Bank Name<br>IFSC Code</th>     
                        <th>UPI Account<br> Linked</th>
                        <!-- <th class="text-center">Today In Transaction<br>Today Out Transaction</th> -->
                        <th class="text-center">Today <br>Volume/Limit</th>
                        <th class="text-center">Total <br>Lifetime Volume</th>
                        <th class="text-center">Today<br> Payout/Settlement</th>
                        <th class="text-center">Total <br>Payout/Settlement</th>
                        <th class="text-center">Current <br>Bank Acc. Balance</th>
                        <th>Status</th>
                        <th>Remarks</th>
                        <th class="text-center" v-if="create_modify_permission">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="!returnData.length && !loading">
                        <td :colspan="create_modify_permission ? 12 : 11" height="300" class="text-center text-muted">
                          <i class="bi bi-bank me-1"></i> No Data Available
                        </td>
                      </tr>
                      <tr v-if="loading">
                        <td :colspan="create_modify_permission ? 12 : 11" class="text-center text-muted" height="400">
                          <div
                            class="spinner-border text-secondary my-2"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                          <br />
                          <div>Loading...</div>
                        </td>
                      </tr>
                      
                      <tr v-for="(value, index) in returnData" :key="index" v-show="!loading">
                        <td>
                          #{{value.id  }}
                        </td>
                        <td>
                          {{value.bank_account_name }}<br>
                          {{value.bank_account_no }}
                        </td>
                        <td>
                          {{value.bank_name }}<br>
                          {{value.ifsc_code }}
                        </td>
                        <td class="position-relative">
                          <b-dropdown variant="light" size="sm" dropup :disabled="!value.upi_accounts.length">
                            <template v-slot:button-content>
                              <span class="text-primary fw-medium font-size-13">
                                UPI Accounts ({{value.upi_accounts.length}})
                              <i class="mdi mdi-chevron-down"></i>
                              </span>
                             
                            </template>
                            <b-dropdown-item
                              class="d-flex align-items-center font-size-13"
                              href="javascript:void(0)"
                              v-for="(v, i) in value.upi_accounts"
                              :key="i"
                              > 
                              <i class="bi bi-check-circle text-primary me-2"></i>
                                <span> 
                                  {{ v.upi_id }}
                                </span>
                              </b-dropdown-item
                            >
                          </b-dropdown>
                          <!-- <div v-if="value.upi_accounts.length">
                            <div class="d-flex align-items-center mb-1" v-for="(v, i) in value.upi_accounts" :key="i">
                              <i class="bi bi-check-circle text-primary me-2"></i>
                              <div> 
                                {{ v.upi_id }}
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            -
                          </div> -->
                        </td>
                        <td class="text-center">
                          <div class="text-success">
                            {{convertCurrencyFormat(value.today_in_amount,true)}} <span v-b-tooltip.hover title="Today In Transaction" >({{ value.today_in_transaction }})</span> 
                            <span class="text-body fw-normal"> / {{convertCurrencyFormat(value.transaction_limit,false)}}</span>
                          </div>
                        </td>
                        <td class="text-center">{{convertCurrencyFormat(value.total_in_amount,true)}}</td>
                        <td class="text-center">
                          <div class="text-danger">
                             {{convertCurrencyFormat(value.today_out_amount,true)}} <span v-b-tooltip.hover title="Today Out Transaction" >({{ value.today_out_transaction }})</span> 
                          </div>
                        </td>
                        <td class="text-center">{{convertCurrencyFormat(value.total_out_amount,true)}}</td>
                        <td class="text-center" style="min-width:130px">
                          <div class="input-group input-group-sm" v-if="create_modify_permission" >
                            <span class="input-group-text bg-info border-info text-white"><span v-if="value.currency">{{ value.currency.symbol }}</span></span>
                            <input type="text" inputmode="decimal" class="form-control form-control-sm font-size-14 text-center " placeholder="Account Balance" v-model="value.current_bank_balance" style="" @input="inputNumberOnlyV2($event, 'returnData','current_bank_balance', index)" @blur="updateBalance(value, index)" :disabled="buttonLoading || loading" required>
                          </div>
                          <div v-else>
                            {{convertCurrencyFormat(value.current_bank_balance,true)}}
                          </div>
                        </td>
                        <td>
                          <span v-if="value.active=='0'" class="text-danger font-size-14 me-1 text-capitalize text-uppercase">&#9679; Inactive</span>
                          <span v-else-if="value.active=='1'" class="text-success font-size-14 me-1 text-capitalize text-uppercase">&#9679; Active</span>
                        </td>
                        <td>
                          {{ value.remarks || '-' }}
                        </td>
                        <td class="text-center" v-if="create_modify_permission">
                          <div>
                            <button class="btn btn-outline-info px-2 py-1 font-size-15 rounded" @click="edit(value)" :disabled="disabled">
                            <i class="uil uil-edit"></i></button>
                            <button class="btn btn-outline-info px-2 py-1 font-size-15 rounded ms-2" @click="remove(value)" :disabled="disabled">
                            <i class="uil uil-trash-alt"></i></button>
                          </div>
                        </td>
                        
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              </div>
              <div class="row d-none" v-if="returnData.length && !loading">
                <div class="col my-3">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        hide-ellipsis
                        @change="handlePageChange"
                        v-model="currentPage"
                        :per-page="perPage"
                        :total-rows="totalData"
                      >
                      </b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            <!-- datatable -->
            <div class="row">
              <div class="col-12">
                <div>
                  <label class="d-inline-flex align-items-center">
                    Filter:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Keyword..."
                      class="form-control ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <div class="col-12 mb-3">
                  <div class="table-responsive mb-0">
                    <!-- striped -->
                      <b-table
                        class="text-nowrap align-middle mb-0 custom-datatable-table"
                        :items="returnData"
                        striped
                        :fields="visibleFields()"
                        responsive="sm"
                        :per-page="perPage2"
                        :current-page="currentPage2"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        :busy="loading"
                        show-empty
                      >        
                      <template>
                        <div class="text-center py-5 text-center text-muted" slot="table-busy">
                         <div
                            class="spinner-border text-secondary my-2"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                          <br />
                          <div>Loading...</div>
                        </div>
                      </template>
                      <template slot="empty">
                        <div class="text-center text-muted py-5">
                          <i class="bi bi-bank me-1"></i> No Data Available
                        </div>
                      </template>
                      <template slot="emptyfiltered">
                        <div class="text-center text-muted py-5">
                          <i class="bi bi-bank me-1"></i> There are no records matching your keyword
                        </div>
                      </template>
                      <!-- <template #cell(index)="data">
                        {{ data.index + 1 }}
                        Whole Index
                        {{ currentPage2 * perPage2 - perPage2 + data.index }}
                      </template>

                      <template #cell(name)="data">
                        {{data.value}}
                      </template> -->
                      <template #cell(current_bank_balance)="data">
                      
                        {{convertCurrencyFormat(data.item.current_bank_balance,true)}}
                        <!-- fields.find(e=>e.key=='current_bank_balance').sortable=true -->
                        <div class="input-group input-group-sm d-none" v-if="create_modify_permission" >
                            <span class="input-group-text bg-info border-info text-white"><span v-if="data.item.currency">{{ data.item.currency.symbol }}</span></span>
                            <input type="text" inputmode="decimal" class="form-control form-control-sm text-center" placeholder="Account Balance" v-model="data.item.current_bank_balance" @input="inputNumberOnlyV2($event, data.item,'current_bank_balance', data.item.custom_index)" @blur="updateBalance(data.item, data.item.custom_index)" :disabled="buttonLoading || loading" required>
                          </div>
                          <div v-else>
                            {{convertCurrencyFormat(data.item.current_bank_balance,true)}}
                          </div>
                      </template>

                      <template #cell(upi_accounts)="data">
                        <b-dropdown variant="light" size="sm" :disabled="!data.item.upi_accounts.length">
                            <template v-slot:button-content>
                              <span class="text-primary fw-medium font-size-13">
                                UPI Accounts ({{data.item.upi_accounts.length}})
                              <i class="mdi mdi-chevron-down"></i>
                              </span>
                             
                            </template>
                            <b-dropdown-item
                              class="d-flex align-items-center font-size-13"
                              href="javascript:void(0)"
                              v-for="(v, i) in data.item.upi_accounts"
                              :key="i"
                              > 
                              <i class="bi bi-check-circle text-primary me-2"></i>
                                <span> 
                                  {{ v.upi_id }}
                                </span>
                              </b-dropdown-item
                            >
                          </b-dropdown>
                      </template>
                      
                      <template #cell(today_in_amount)="data">
                        
                        <div class="text-success">
                          {{ convertCurrencyFormat(data.item.today_in_amount,true)}} 
                          <span class="text-body fw-normal"> / {{convertCurrencyFormat( data.item.transaction_limit,false)}}</span>
                        </div>
                        
                      </template>

                      <template #cell(active)="data">
                        <span v-if="data.item.active=='0'" class="text-danger font-size-14 me-1 text-capitalize text-uppercase">&#9679; Inactive</span>
                          <span v-else-if="data.item.active=='1'" class="text-success font-size-14 me-1 text-capitalize text-uppercase">&#9679; Active</span>
                      </template>


                      <template #cell(action)="data">
                          <div v-if="create_modify_permission">
                            <button class="btn btn-outline-info px-2 py-1 font-size-15 rounded" @click="edit(data.item)" :disabled="disabled">
                            <i class="uil uil-edit"></i></button>
                            <button class="btn btn-outline-info px-2 py-1 font-size-15 rounded ms-2" @click="remove(data.item)" :disabled="disabled">
                            <i class="uil uil-trash-alt"></i></button>
                          </div>
                      </template>

                      <template #cell(bank_name)="data">
                        {{ data.item.bank_name  }} 
                        <div>  {{ data.item.ifsc_code  }} </div>
                      </template>

                      <template #cell(id)="data">
                        #{{ data.item.id }}
                      </template>
                    </b-table>
                  </div>
                  
              </div>
              <div class="col-12" v-if="returnData.length && !loading">
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage2"
                        :total-rows="totalData"
                        :per-page="perPage2"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- datatable end -->
              <div class="col-12 col-md-8">
                <div class="row d-none">
                  <div class="col-12 col-md-auto px-md-1 mb-3">
                    <label class="form-label mb-1">Method</label>
                    <select class="form-select pe-5" id="1" v-model="seletedMethod" @change="handlePageChange(1);" :disabled="disabled">
                      <option value="">All</option>
                      <option value="auto">Auto</option>
                      <option value="manual">Manual</option>
                    </select>
                  </div>
                  <div class="col-12 col-md-3 px-md-1 mb-3">
                    <label class="form-label mb-1">Status</label>
                    <select class="form-select pe-5" id="1" v-model="selectedStatus" @change="handlePageChange(1);" :disabled="disabled">
                      <option value="">All Status</option>
                      <option value="processing">Processing</option>
                      <option value="successful">Successful</option>
                      <option value="pending">Pending</option>
                      <option value="failed">Failed</option>
                      <option value="tagged_successful">Tagged Successful</option>
                    </select>
                  </div>
                </div>
              </div>
              
              <div class="col-12 col-md mb-3 d-none">
                <label class="form-label mb-1">Transaction Id</label>
                <div>
                  <input
                    type="text"
                    placeholder="Transaction Id"
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchTransactionId"
                  />
                </div>
              </div>
              <div class="col-12 col-md mb-3 d-none">
                <label class="form-label mb-1">Merchant Item Id</label>
                <div>
                  <input
                    type="text"
                    placeholder="Merchant Item Id"
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchMerchantItemId"
                  />
                </div>
              </div>
              <div class="col-12 col-md mb-3 d-none">
                <label class="form-label mb-1">Amount</label>
                <div>
                  <input
                    type="tel" @input="inputNumberOnly($event, 'searchAmount')"
                    placeholder="Amount"
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchAmount"
                  />
                </div>
              </div>
              <div class="col-12 col-md-auto text-md-end mb-3 mb-lg-0  d-none">
                <label class="form-label mb-1 d-md-block d-none">&nbsp;</label>
                <div class="d-flex w-100">
                  
                  <button
                      class="btn btn-light col-auto mb-0 me-2 text-nowrap d-grid"
                      type="button"
                      :disabled="disabled"
                      @click="clear()"
                    >
                      <span><i class="uil-redo me-1"></i> Clear</span>
                    </button>
                    <button
                      class="btn btn-info text-white me-0 text-nowrap d-grid"
                      type="button"
                      :disabled="disabled"
                      @click="search()"
                    >
                      <span><i class="uil-search me-1"></i> Search</span>
                    </button>
                    
                </div>

              </div>
              <div class="col-12 m-0 p-0"></div>
              <div class="col-12 col-lg-12 col-xl d-none">
                <label class="form-label mb-1">Filter By</label>
                <div class="input-group mb-3">
                    <select class="form-select pe-5" v-model="filterType">
                      <option value="created_date">Created Date</option>
                      <option value="updated_date">Updated Date</option>
                    </select>
                  </div>
              </div>
              <div class="col-12 col-md col-xl mb-0 d-none">
                <label class="form-label mb-1">Date Range</label>
                <date-picker
                  v-model="date_range"
                  type="date"
                  range
                  value-type="format" 
                  format="YYYY-MM-DD"
                  placeholder="Select Date Range"
                  :disabled="disable_date"
                  :disabled-date="notBeforeToday"
                  :clearable="false"
                  :editable="false"
                  :shortcuts="shortcuts"
                  class="mb-3"
                ></date-picker>
                <!-- 
                  @change="DateChanged()" -->
              </div>
             
              <div class="col-12 col-xl d-none">
                <div class="float-end">
                  <div class="mb-3 ml-auto d-none ">
                    <b-button
                      type="submit"
                      variant="info"
                      @click="exportData()"
                      :disabled="disableExport"
                    
                    >
                      <i class="uil-file-export me-1"></i>
                      Export
                      <div
                        class="spinner-border spinner-border-sm text-white"
                        v-if="loadingButton"
                        role="status"
                      ></div>
                    </b-button>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="'/animate/loading_purple.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
    <bankAccounts ref="modalFunc" :data="{'accessUsername':accessUsername, 'accessToken':accessToken, 'updateData':updateData}"  @callParentFunction="handlePageChange(currentPage)" />
  </Layout>
</template>

<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import bankAccounts from "@/views/pages/app/modal/createEditBankAccounts";
/**
 * Page
 */
const PageTitle = "Bank Accounts"
const CancelToken = axios.CancelToken;
let cancel;

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common,
    DatePicker,
    bankAccounts
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      defaultName: appConfig.defaultAccountName,
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      account_type:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData:{
        apiID: "YOUR_API_ID",
        apiHash:"YOUR_API_HASH",
      },
      submitted:false,
      loading:false,
      buttonLoading:false,
      currentPage: 1,
      currencySymbol:"₹",
      seletedMethod: "",
      selectedStatus:"",
      searchTransactionId: "",
      searchMerchantItemId:"",
      filterType:"created_date",
      sortByData:"",
      searchAmount:"",
      perPage: 20,
      totalData: 0,
      returnData: [],
      returnTotalAmount: 0,
      returnTotalTransaction:0,
      returnSuccessRate: 0,
      export_data: [],
      search_value: "",
      disableExport: false,
      loadingButton: false,
      date_range: null,
      disable_date: false,
      disabled:false,
      shortcuts:[],
      currentAccount:{},
      currentMerchantInfo:{},
      currentResellerInfo:{},
      updateData:{},
      create_modify_permission:false,
      summary_data:{
        today_in_transaction:0,
        today_in_volume:0,
        total_in_volume:0,
        total_all_bank_account_balance:0,
        today_out_transaction:0,
        today_out_volume:0,
        total_out_volume:0,
        total_active_bank_account:0
      },
      //datatable
      totalRows: 1,
      currentPage2: 1,
      perPage2: 20,
      filter: "",
      filterOn: [],
      sortBy: "today_in_transaction",
      sortDesc: true,
      fields: [
         {
          key: "id",
          label:"ID",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        {
          key: "bank_account_name",
          label:"Bank Acc. Name",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        {
          key: "bank_account_no",
          label:"Bank Acc. No",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        {
          key: "bank_name",
          label:"Bank Name/IFSC Code",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        {
          key: "upi_accounts",
          label:"UPI Acc. Linked",
          thClass:"user-select-none",
          tdClass: "align-middle",
          formatter: (value) => {
            if (Array.isArray(value)) {
              return value.length;
            } else {
              return 0; // or any default value if upi_accounts is not an array
            }
          },
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: false
        },
        {
          key: "today_in_transaction",
          label:"Today In Trx.",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        {
          key: "today_in_amount",
          label:"Today In Amount/Limit",
          thClass:"user-select-none",
          tdClass: "align-middle",
          formatter: (value) => {
            if (value) {
              return this.convertCurrencyFormat(value,true);
            } else {
              return 0; // or any default value if upi_accounts is not an array
            }
          },
          sortable: true,
        },
        {
          key: "total_in_amount",
          label:"Total In Amount",
          thClass:"user-select-none",
          tdClass: "align-middle",
          formatter: (value) => {
            if (value) {
              return this.convertCurrencyFormat(value,true);
            } else {
              return 0; // or any default value if upi_accounts is not an array
            }
          },
          sortable: true,
        },
        {
          key: "today_out_transaction",
          label:"Today Out Trx.",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        {
          key: "today_out_amount",
          label:"Today Out Amount",
          thClass:"user-select-none",
          tdClass: "align-middle",
          formatter: (value) => {
            if (value) {
              return this.convertCurrencyFormat(value,true);
            } else {
              return 0; // or any default value if upi_accounts is not an array
            }
          },
          sortable: true,
        },
        {
          key: "total_out_amount",
          label:"Total Out Amount",
          thClass:"user-select-none",
          tdClass: "align-middle",
          formatter: (value) => {
            if (value) {
              return this.convertCurrencyFormat(value,true);
            } else {
              return 0; // or any default value if upi_accounts is not an array
            }
          },
          sortable: true,
        },
        {
          key: "current_bank_balance",
          label:"Bank Acc. Balance",
          thClass:"user-select-none",
          tdClass: "align-middle",
          formatter: (value) => {
            if (value) {
              return this.convertCurrencyFormat(value,false);
            } else {
              return 0; // or any default value if upi_accounts is not an array
            }
          },
          sortable: true,
        },
        {
          key: "active",
          label:"Status",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        {
          key: "remarks",
          label:"Remarks",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        {
          key: "action",
          label:"Action",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: false,
          visible: this.create_modify_permission
        },
      ],
    };
    //datatable
  },
  middleware: "authentication",
  async mounted(){
   
    this.title = PageTitle
    this.items[1].text = PageTitle
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    await this.reload()
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    this.date_range = this.$refs.commonFunc.last30Days()
    this.shortcuts = this.$refs.commonFunc.datePickerShortcut()
  
    // if (this.account_type == 'admin'){
    //   this.getReseller();
    // }
    this.currentAccount = {
      "account_db_code": "",
      "account_name": "Select an Account",
      "account_type": "admin",
      "up_reseller_count": 0,
      "reseller_parent": [],
      "reseller_level": "",
      "reseller_code": "",
      "merchant_count": 0
    }
    this.handlePageChange(1);
    //this.tabsChanging(0)
  }, 
  created(){
  },
  methods:{
    visibleFields() {
      return this.fields.filter(field => {
      // Include all fields except the "Action" column if the condition is true
      return field.key !== 'action' || this.create_modify_permission;
    });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage2 = 1;
    },
    tabsChanging: function(e){
      console.log(this.accessUsername);
      console.log(e);
    },
    create(value){
      this.updateData=value;
      setTimeout(() =>  this.$refs.modalFunc.showModal(),100)
    },
    edit(value){
      this.updateData=value;
      setTimeout(() =>  this.$refs.modalFunc.showEditModal(),100)
    },
    remove(value) {
        Swal.fire({
          icon: 'info',
          title: `Confirm deletion, ${value.bank_account_name}?`,
          html: `Are you sure you want to delete this ${value.bank_account_no}? This action is irreversible, so make sure you want to proceed.`,
          confirmButtonColor: '#FA6541',
          iconColor: '#FF4500',
          showCancelButton: true,
          confirmButtonText: "Yes"
        }).then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.loading = true
            this.disabled = true
            var bodyFormData = new FormData();
            bodyFormData.append("accessToken", this.accessToken);
            bodyFormData.append("accessUsername", this.accessUsername);
            bodyFormData.append("accountDbCode", value.account_db_code);
            axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/bankAccount/deleteBankAccount',
              //url: appConfig.DemoAPI,
              data: bodyFormData,
              headers: {
                "Content-Type": "multipart/form-data"
              },
            }).then((response) => {
              this.returnData = []
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                this.handlePageChange(1)
                Swal.fire({
                  icon: 'success',
                  title: `${value.bank_account_name} Deleted Successfully`,
                  html: 'The selected bank account has been successfully deleted.',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                })
              } else if (resData.status == 440) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                  path: "/login",
                });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
              }
              this.loading = false
              this.disabled = false
              this.$Progress.finish();
            }).catch((error) => {
              this.disabled = false;
              this.loading = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            });
          }
        })
        
        
      },
      detectObject(value){
        if (typeof value === 'object'){
          return true
        }else{
          return false
        }
      },
      convertCurrencyFormat(value,show00) {
        if (show00==true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        }else{
           // Assuming this.transactionLimit is defined in your component's data
           return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
        }
      },
      inputNumberOnly(event, parentModel) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel] = numericValue;
      },
      
      inputNumberOnlyV2(event, parentModel, childModel, index) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][index][childModel] = numericValue
        // if(numericValue.length > 2){
        //     var val = numericValue.replace(/[^0-9]/g, '');
        //     console.log(val);
        //     val = val.substr(0, val.length-2)+"."+val.substr(-2);
        //     this[parentModel][childModel] = val;
        // }
      },
      updateBalance(value, index) {
        if (value.current_bank_balance == value.record_last_bank_balance){
          return;
        }else{
          Swal.fire({
          icon: 'info',
          title: `Confirm Update ${value.bank_account_name}'s Bank Account Balance?`,
          html: `<div class="font-size-14">Are you sure you want to update the account balance for ${value.bank_account_name} from this bank account ${value.bank_account_no}? This action is irreversible, so please ensure you want to proceed.</div>
          <div class="font-size-15 my-3 text-start">
          <label>Password</label>
          <input type="password" id="verifyPassword" class="form-control form-control-lg" placeholder="Enter Your Password" required>
          </div>
          `,
          confirmButtonColor: '#FA6541',
          iconColor: '#FF4500',
          showCancelButton: true,
          confirmButtonText: "Yes",
          preConfirm: () => {
            if ( document.getElementById("verifyPassword").value===''){
              Swal.showValidationMessage("Please enter your password");
              return false
            }
              return [
                document.getElementById("verifyPassword").value
              ];
            }
        }).then((result) => {
          console.log(result)
          if (result.isConfirmed) {
            const password = result.value[0];
            this.$Progress.start();
            this.loading = true
            this.disabled = true
            var bodyFormData = new FormData();
            bodyFormData.append("accessToken", this.accessToken);
            bodyFormData.append("accessUsername", this.accessUsername);
            bodyFormData.append("accountDbCode", value.account_db_code);
            bodyFormData.append("currentBankBalance", value.current_bank_balance);
            bodyFormData.append("password", password);
            axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/bankAccount/updateBankBalance',
              //url: appConfig.DemoAPI,
              data: bodyFormData,
              headers: {
                "Content-Type": "multipart/form-data"
              },
            }).then((response) => {
              this.returnData = []
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: `${value.bank_account_name}'s' Account Bank Balance Update Successfully`,
                  html: 'The selected bank account balance has been successfully update.',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                })
              } else if (resData.status == 440) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                  path: "/login",
                });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
              }

              this.handlePageChange(this.currentPage)
              this.loading = false
              this.disabled = false
              this.$Progress.finish();
            }).catch((error) => {
              this.disabled = false;
              this.loading = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            });
          }else{
            this.returnData[index].current_bank_balance = this.returnData[index].record_last_bank_balance
          }
       
        })
        }
        
      },
      returnBankList(){
        if (this.selectedCountry.id !==-1){
          return this.banksList.filter(bank => bank.countryId === this.selectedCountry.id);
        }else{
          return this.banksList
        }
      },
      notBeforeToday(date) {
        return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
      DateChanged() {
        console.log("datechange" + this.date_range);
        this.handlePageChange(1);
        this.currentPage = 1;
        this.disable_date = true;
      },
      handlePageChange(current_page) {
        this.pageNum = current_page;
        this.currentPage = current_page;
        this.getData(this.pageNum, this.search_value);
        //this.exportData(1)
      },

      search() {
        this.handlePageChange(1);
        this.currentPage = 1;
      },
      clear() {
        this.seletedMethod= ""
        this.selectedStatus= ""
        this.searchTransactionId= ""
        this.searchMerchantItemId= ""
        this.filterType="created_date"
        this.searchAmount=""
        this.sortByData=""
        this.date_range = this.$refs.commonFunc.last30Days()

        this.search_value = "";

        this.summary_data={
          today_in_transaction:0,
          today_in_volume:0,
          total_in_volume:0,
          total_all_bank_account_balance:0,
          today_out_transaction:0,
          today_out_volume:0,
          total_out_volume:0,
          total_active_bank_account:0
        }
        this.handlePageChange(1);
        this.currentPage = 1;
      },

      exportToExcel(Data,name,type) {
        var title = name.replace(/ /g, "_");
        var ws = window.XLSX.utils.json_to_sheet(Data);
        let objectMaxLength = []; 
            for (let i = 0; i < Data.length; i++) {
            let value = Object.values(Data[i]);
            for (let j = 0; j < value.length; j++) {
                if (typeof value[j] == "number") {
                objectMaxLength[j] = 5;
                } else {
                objectMaxLength[j] =
                    objectMaxLength[j] >= value[j].length
                    ? parseInt(objectMaxLength[j])
                    : parseInt(value[j].length) + 5;
                }
            }
        }
        var wscols =[]
        objectMaxLength.forEach((value, index) => {
          console.log(value)
          wscols.push({width: objectMaxLength[index]})
        }),
        ws['!cols'] = wscols;
        
        var wb = window.XLSX.utils.book_new();
        window.XLSX.utils.book_append_sheet(wb, ws, name)
        window.XLSX.writeFile(wb, title +'.' + (type || 'xlsx'))
    },
    async getData(pages, keyword){
      if(cancel !== undefined) cancel();
      console.log("getData")
      this.$Progress.start();
      this.loading = true
      this.disabled = true
      this.disable_date = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", pages);
      bodyFormData.append("limit", this.perPage);
      bodyFormData.append("keyword", keyword);
      bodyFormData.append("sortBy", this.sortByData);
      // bodyFormData.append("dateStart", this.date_range[0]);
      // bodyFormData.append("dateEnd", this.date_range[1]);
      console.log(keyword)
      axios({
          method: "post",
          url: appConfig.APIHostAdmin + "controller/bankAccount/getAllBankAccountList",
          // method: "get",
          // url: "/api/bank_accounts.json",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
          cancelToken: new CancelToken(c => cancel = c)
      })
      .then((response)=>{
          this.returnData = [];
          var resData = response.data;
          if (resData.status == 200) {
            this.totalData = resData.total;

            this.summary_data={
              today_in_transaction:resData.today_in_transaction || 0,
              today_in_volume:resData.today_in_volume || 0,
              total_in_volume:resData.total_in_volume || 0,
              total_all_bank_account_balance:resData.total_all_bank_account_balance || 0,
              today_out_transaction:resData.today_out_transaction || 0,
              today_out_volume:resData.today_out_volume || 0,
              total_out_volume:resData.total_out_volume || 0,
              total_active_bank_account:resData.total_active_bank_account || 0
            }

            var responseData = resData.data;
            //this.returnData = responseData;
            
            responseData.forEach((element,index) => {
              this.returnData.push(
                {
                  "account_db_code": element.account_db_code,
                  "id": element.id,
                  "currency": element.currency,
                  "type": element.type,
                  "bank_account_name": element.bank_account_name,
                  "bank_account_no": element.bank_account_no,
                  "bank_name": element.bank_name,
                  "ifsc_code": element.ifsc_code,
                  "today_in_transaction":element.today_in_transaction,
                  "total_in_amount": element.total_in_amount,
                  "today_in_amount": element.today_in_amount,
                  "today_out_transaction":element.today_out_transaction,
                  "total_out_amount": element.total_out_amount,
                  "today_out_amount": element.today_out_amount,
                  "record_last_bank_balance": element.current_bank_balance,
                  "current_bank_balance": element.current_bank_balance,
                  "transaction_limit": element.transaction_limit,
                  "remarks": element.remarks,
                  "upi_accounts": element.upi_accounts,
                  "created_at": element.created_at,
                  "active":element.active,
                  "custom_index":index
                }
              )
            });
          } 
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.loading = false;
          this.disabled = false;
          this.disable_date = false;
          this.$Progress.finish();
      })
      .catch((error)=> {
          this.loading = false;
          this.disabled = false
          this.disable_date = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
      });
    },
    async exportData(){
        this.$Progress.start();
        this.disableExport = true;
        this.loadingButton = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("phone",  this.accessPhone);
        // bodyFormData.append("page", pages);
        // bodyFormData.append("limit", this.perPage);
        axios({
            method: "get",
            url: `https://dummyjson.com/users/search?q=&limit=100&skip=1`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.export_data = [];
            var resData = response.data;
            this.totalData = resData.total
            var users = resData.users
           

            for (var i in users) {
                 // Combine date and time
                 const depositDateTime = `29 Jun 2023, 12:30:00`;
                // Generate a random deposit amount
                const randomDeposit = Math.floor(Math.random() * 10000) + 1000; // Random amount between 1000 and 11000

                // Calculate merchant fee and nett amount
                const merchantFeePercentage = 2.5 / 100; // 2.5% merchant fee
                const merchantFee = Math.floor(randomDeposit * merchantFeePercentage);
                const nettAmount = randomDeposit - merchantFee;
                const randomStatus = Math.random() < 0.8 ? "success" : "failed";
                var randomMode = Math.random() < 0.8 ? "UPI" : "IMPS";
                var transaction_id = ""
                if (randomMode=="UPI"){
                  transaction_id = "320020673657"
                }else{
                  transaction_id = "IMPS/123443211240"
                }

                const listData = {
                  "id":"ids",
                  "deposit_date": depositDateTime,
                  "customer_id": `${users[i].id}`,
                  "customer_name":`${users[i].firstName} ${users[i].lastName}`,
                  "deposit_amount": randomDeposit.toString(),
                  "deposit_mode":randomMode.toString(),
                  "merchant_fee": merchantFee.toString(),
                  "nett_amount": nettAmount.toString(),
                  "transaction_id":transaction_id,
                  "status": randomStatus,
                };
                this.export_data.splice(i, 0, listData);
              }
              var self = this
              setTimeout(function() {  
                self.disableExport = false;
                self.loadingButton = false;
                self.exportToExcel(self.export_data,'Summary','xlsx')
              }, 1200);
             
            // if (resData.status == 200) {
            //   console.log(resData)
            //   this.totalData = 10
            // } 
            // else if (resData.status == 440){
            //   Swal.fire(
            //       {
            //       icon: 'error',
            //       title: 'Oops...',
            //       html: `${resData.message}.`,
            //       confirmButtonColor: '#222',
            //       confirmButtonText: this.$t('siteLang.Done'),
            //     })
            //     localStorage.clear();
            //     this.$router.push({
            //         path: "/login",
            //   });
            // }
            // else {
            //     Swal.fire(
            //       {
            //       icon: 'error',
            //       title: 'Oops...',
            //       html: `${resData.message}.`,
            //       confirmButtonColor: '#222',
            //       confirmButtonText: this.$t('siteLang.Done'),
            //     })
            // }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.disableExport = false;
            this.loadingButton = false;
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
          this.currentMerchantInfo = data.merchant_info;
          this.currentResellerInfo = data.reseller_info;
          const permission = data.modules.filter(e => e.name==='administrator')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
            console.log(this.create_modify_permission)
          } else {
            console.log('Module with name "administrator" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>
<style scoped>
.custom-input:focus{
  border-color: #624329;
}
.total-count-data h4{
  font-size:18px !important;
}
.table th{font-size: 13px !important;}
</style>